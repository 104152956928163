<!-- // 4 撰写页面的主菜单 -->

<template>
  <div id="writer_menu">
    <div id="nav">
      <el-image :src="require('../../image/writer_menu_bg.png')" class="poster"/>

      <div class="table_bg">

        <router-link to="/writer_page" >
          <img src="../../image/writer_talk.png" class="img_writer1">
        </router-link>
        <router-link to="/writer_menu_note" replace>
            <img src="../../image/talk_record.png" class="img_writer2">
          </router-link>
          <router-link to="/writer_menu_draft" >
              <img src="../../image/talk_draft.png" class="img_writer3">
          </router-link>

      </div>

      <!--        返回键-->
      <router-link to="/index_home" class="icon-back">
        <img src="../../image/back_icon_white.png" style="width: 12%; height: 20%; "></router-link>


    </div>
    
    <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a>

  </div>

</template>

<script>
  export default {
    name: 'writer_menu',
    data() {
      return {

      }
    },
    methods: {
      // writer_page() {
      //   this.$router.push('/writer_page');//要跳转的界面
      // },
      // writer_menu_note() {
      //   this.$router.push('/writer_menu_note');//要跳转的界面
      // },
      // writer_menu_draft() {
      //   this.$router.push('/writer_menu_draft');//要跳转的界面
      // },
    }


  }
</script>



<style scoped>
  .poster {
    position: absolute;
    /*margin-top:-180px;*/
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .table_bg{
    position: absolute;
    width: 90%;
    height: 10%;
    top: 25%;
    left: 4.5%;
    /*background: #0b0b0b;*/
    /* background: #e29e3b; */
  }
  .table{
    position: relative;

    /*size: 200px 300px;*/
    /*width: 100%; margin-top: 180px; height: 400px;*/
    top: 3%;
    left: 2%;
    width: 95%;
    height: 80%;
  }
  .icon-back{
    /*margin-bottom: 80%;*/
    position: absolute;
    top:22%;
    left: -16%;
  }
  #nav {
    padding: 20px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }


  .img_writer1{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    right: 8%;
    width:23%;
    height: 50%;
   }

  .img_writer2{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    margin:0px auto;
    width:23%;
    height: 50%;
  }
  .img_writer3{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    left: 8%;
    width:23%;
    height: 50%;
  }

  .beian{
    font-family: "黑体";
    color: #FFFFFF;
    font-size: 11px;
    position: fixed;
    left: 35%;
    top: 97%;
}

</style>
