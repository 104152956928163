

<template>
  <div id="writer_menu">
    <div id="nav">
      <el-image :src="require('../../image/check_page.png')" class="poster"/>

      <div class="table_bg">
        <template>
          <!--            Dear框 display:inline; -->
          <div class="writer_page">
            <p style="top: -1%;left: 4%;position: absolute;font-weight: bold; font-size: 24px;">Dear</p>
            <div class="writer_Dear">
              <el-input v-model="dear_fname" placeholder="请输入收信人的昵称" @blur="inputBlur"></el-input >
            </div>
            <p></p>
            <div class="page_background">
              <div class="page">
                <el-input type="textarea" v-model="msg" :rows="8" maxlength="500" :show-word-limit='true' resize='none' class="textarea" @blur="inputBlur"></el-input >
              </div>
            </div>

            <div class="font-bg">
              <!--            from框-->
              <div class="writer_from" style="margin-left:50%; margin-top: 8%; font-weight: bold; ">
                <p style="top:-6%; right:41%;position: absolute;font-size: 24px;">From</p>
                <el-input v-model="from_fname"  placeholder="发送人昵称" label-width="70px" @blur="inputBlur"></el-input >
              </div>

              <router-link to="/writer_menu">
                <img src="../../image/back_icon.png" class="img_writer1">
              </router-link>
              <img src="../../image/complete_icon.jpg" @click="handleSetMsg" class="img_writer2">
              <img src="../../image/draft_icon.jpg" @click="handleSetDraft" class="img_writer3">
            </div>

          </div>
        </template>



      </div>




    </div>
      <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a>

  </div>

</template>


<script>
  export default {
    components: {
    },

    data() {
      return {
        dear_fname: '', //接收人
        msg:'',
        from_fname: '',    //发送人
      };
    },
    mounted() {
      const originalHeight=document.documentElement.clientHeight ||document.body.clientHeight;
      window.onresize = ()=>{
        return(()=>{
          //键盘弹起与隐藏都会引起窗口的高度发生变化
          const resizeHeight=document.documentElement.clientHeight || document.body.clientHeight;
          console.log("进入到判断页面高度=========");
          console.log("页面初始高度========="+originalHeight);
          console.log("软键盘弹起高度========="+resizeHeight);
          if(resizeHeight-0<originalHeight-0){
//当软键盘弹起，在此处操作
            console.log("进入到软键盘弹起=========");
            document.querySelector('body').setAttribute('style', 'height:'+originalHeight+'px;');
            this.scrollerHeight=resizeHeight;
          }else{
            //当软键盘收起，在此处操作
            console.log("进入到软键盘收起=========");
            document.querySelector('body').setAttribute('style', 'height:100%;');
            this.scrollerHeight="100%";
          }
        })()

      },

// this.msg = this.$router.params.msg
              console.log(this.$router.params)
// if(this.$router.params){
      this.msg = sessionStorage.getItem("msg");
// }
//  this.$on("msg",(val)=>{
      //     console.log(val)
      //     this.msg = val
      // })

    },
    methods: {
//对应的methods中添加js
      inputBlur () {
        window.scrollTo(0, 0)
      },
      writer_menu() {
        this.$router.push('/writer_menu');//要跳转的界面
      },
      handleSetMsg(){
// if(this.from_fname == ''){
//   this.$message({
//     message: '收信人的昵称不能为空',
//     type: 'warning'
//   });
// }else if(this.dear_fname == ''){
//   this.$message({
//     message: '发送人的昵称不能为空',
//     type: 'warning'
//   });
// }else
        if(this.msg == ''){
          this.$message({
            message: '内容不能为空',
            type: 'warning'
          });
        }else{
          this.$store.commit('setIdType', 'ToId');
          this.$store.commit('setPlaceholder', '请输入对方的手机号码');
          this.$store.commit('setFromName', this.from_fname);
          this.$store.commit('setToName', this.dear_fname);
          this.$store.commit('setMsg', this.msg);
          this.$router.push('writer_admin_log');
        }
      },
// handleSetMsg(){
//   if(this.from_fname == ''){
//     this.$message({
//       message: '发件人ID不能为空',
//       type: 'warning'
//     });
//   }else if(this.dear_fname == ''){
//     this.$message({
//       message: '收件人ID不能为空',
//       type: 'warning'
//     });
//   }else if(this.msg == ''){
//     this.$message({
//       message: '内容不能为空',
//       type: 'warning'
//     });
//   }else{
//     let that = this
//     this.$axios({
//       method: 'post',
//       url: " http://api.restmode.cn:5000/api/setmsg",
//       data:{
      //         userId:that.$store.getters.getUserId,
      //         toId:that.dear_fname,
      //         fname:that.from_fname,
      //         msg:that.msg
      //       }
//     }).then(result =>{
//         if (result.data.code == 0){
//           this.$message({
//             message: result.data.message,
//             type: 'success'
//           });
//           that.$router.go(-1);
//         }
//     }).catch((e) => {console.log(e)});
//   }

// },
      handleSetDraft(){
        if(this.msg == ''){
          this.$message({
            message: '内容不能为空',
            type: 'warning'
          });
        }else{
          let that = this
          this.$axios({
            method: 'post',
            url: "http://api.restmode.cn:5000/api/savedraft",
            data:{
              userId:that.$store.getters.getUserId,
              tname:that.dear_fname,
              fname:that.from_fname,
              msg:that.msg
            }
          }).then(result =>{
            if (result.data.code == 0){
              this.$message({
                message: result.data.message,
                type: 'success'
              });
            }
          }).catch((e) => {console.log(e)});
        }
      }
    }
  };
</script>




<style scoped>
  .poster {
    position: absolute;
    /*margin-top:-180px;*/
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .table_bg{
    position: absolute;
    width: 86%;
    height: 65%;
    top: 22%;
    left: 7%;
    background: #ffffff;
    /*background: #d1902c;*/
  }

  .page{
    position: absolute;
    width: 100%;
    /*height: 70%;*/
    top: 3%;

  }
  .page_background{
    position: relative;
    width: 100%;
    height: 100%;
    left: 3%;
    top: -5%;
    /* background-image: url('../../image/page.png'); */

  }

  /* 文本输入框 */
  .textarea >>> .el-textarea__inner{
    font-size:23px !important;
    background: transparent;
    background-size: 100% 100%;
    background-image: url('../../image/page.png');
    border-color: #FFFFFF;
  }

  /*昵称输入框样式*/
  .writer_Dear >>> input{
    margin-top: 8%;
    margin-left: -5%;
    width: 47%;
    height: 28px;
    font-size: 18px;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    /*box-shadow: none;*/
    border-bottom: 1px solid #000000;
  }
  
  .font-bg{
    position: absolute;
    width:105.3%;
    height: 30%;
    left: -4%;
    top: 108%;
    /*background: #0a628f;*/

  }

/* 发送人框 */
  .writer_from >>> input{
    position: relative;
    width:60%;
    font-size: 18px;
    height: 20%;
    top:-100%;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #000000;
  }

  .writer_page{
    position: relative;
    width: 95%;
    height: 68%;
    left: 3.8%;
  }


  /* #nav a {
    font-weight: bold;
    color: #2c3e50;
  } */

  .img_writer1{
    /*margin-bottom: 80%;*/
    position:relative;
    /*width: 48px;*/
    /*height: 40px;*/
    width: 50px;
    height: 30px;
    right: 20%;
    top: 65%;
    margin-top: 3%;
  }
  .img_writer2{
    /*/*margin-bottom: 80%;*!compoents*/
    position:relative;
    width: 65px;
    height: 30px;
    top: 65%;
  }
  .img_writer3{
    /*margin-bottom: 80%;*/
    position:relative;
    width: 50px;
    height: 30px;
    left: 20%;
    top: 65%;
  }


.beian{
    font-family: "黑体";
    color: #FFFFFF;
    font-size: 11px;
    position: fixed;
    left: 35%;
    top: 97%;
}

</style>

