import Vue from 'vue'
import VueRouter from 'vue-router'
import check_admin_log from "../components/check_admin_log"; //查看方式下 用户登录页面
import index_home from '../components/index_home'; //首页
import check_page from "../components/check_page";   //查看页面下  查看留言
import writer_menu from "../components/writer_menu";   //撰写页面菜单
import writer_menu_draft from "../components/writer_menu_draft";   //撰写页面下的 查看草稿
import writer_menu_note from "../components/writer_menu_note";   //撰写页面下的 查看留言记录
import writer_page from "../components/writer_page";  //撰写页面下 编写页面
import writer_admin_log from "../components/writer_admin_log";  //撰写页面 发送指定用户

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'check_admin_log',
    component: check_admin_log
  },
  {
    path: '/index_home',
    name:'index_home',
    component: index_home
  },
    {
      path: '/check_page',
      name:'check_page',
      component: check_page
    },
    {
      path: '/writer_menu',
      name:'writer_menu',
      component: writer_menu
    },
    {
      path: '/writer_menu_draft',
      name: 'writer_menu_draft',
      component: writer_menu_draft
    },
    {
      path: '/writer_menu_note',
      name: 'writer_menu_note',
      component: writer_menu_note
    },
    {
      path: '/writer_page',
      name: 'writer_page',
      component: writer_page
    },
    // {
    //   path: '/check_admin_log',
    //   name: 'check_admin_log',
    //   component: check_admin_log
    // },
    {
      path: '/writer_admin_log',
      name: 'writer_admin_log',
      component: writer_admin_log
      },
    // //路由重定向
    // {
    //   path: '*',
    //   name: 'any',
    //   redirect: '/check_admin_log'
    // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
