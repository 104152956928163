import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId:'',
    idtype: '',
    fname: '',
    tname: '',
    tId:'',
    msg:'',
    placeholder:'请输入您的手机号码'
  },
  getters: {
    getUserId: state => {
      return state.userId
    },
    getIdType: state => {
      return state.idtype
    },
    getFromName: state => {
      return state.fname
    },
    getToName: state => {
      return state.tname
    },
    getToId: state => {
      return state.tid
    },
    getMsg: state => {
      return state.msg
    },
    getPlaceholder: state => {
      return state.placeholder
    }
  },
  mutations: {
    setUserId (state, uid) {
      state.userId = uid
    },
    setIdType (state, idtype) {
      state.idtype = idtype
    },
    setFromName (state, fname) {
      state.fname = fname
    },
    setToName (state, tname) {
      state.tname = tname
    },
    setToId (state, tid) {
      state.tid = tid
    },
    setMsg (state, msg) {
      state.msg = msg
    },
    setPlaceholder(state, placeholder){
      state.placeholder = placeholder
    }
  },
  actions: {
  },
  modules: {
  }
})
