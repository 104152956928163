<!-- // 2 索引页面 -->


<template>
  <div id="index">
    <div id="nav">
      <el-image :src="require('../../image/index.png')" class="poster"/>
<!--      <li><mt-button size="large" @click="go">测试跳转</mt-button></li>-->
      <div class="table_bg">
      <router-link to="/check_page" replace><img src="../../image/index_check.png" class="img_check"></router-link>
         |
      <router-link to="/writer_menu" replace><img src="../../image/index_writer.png" class="img_writer"></router-link>
      </div>

    </div>
    <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a>

  </div>

</template>


<script>

  export default {

    data(){
      return {}
    },
    methods: {

    }
  }
</script>

<style scoped>
.poster {
  position: absolute;
  /*margin-top:-180px;*/
  top:0;
  left:0;
  width:100%;
  height:100%;

}

#index {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
.table_bg{
  position: absolute;
  width: 80%;
  height: 40%;
  /*min-height:200px;*/
  top: 50%;
  left: 10%;
  background: #ffffff;
  /*background: #d1902c;*/
}
.img_check{
  /*margin-bottom: 80%;*/
  position:relative;
  /*transform:translateY(5%);!**下移元素**!*/
  /*margin-top: 50%;*/
  margin-left: -46%;
  width: 58%;
  /*min-width: 170px;*/
  height: 100%;

  /*transform:scale(0.4, 0.5);*/
}

.img_writer{
  /*margin-bottom: 80%;*/
  position:relative;
  /*transform:translateY(5%);!**下移元素**!*/
  /*margin-top: 85%;*/
  margin-right:-46%;
  width:58%;
  height: 100%;
}

.beian{
    font-family: "黑体";
    color: #000000;
    font-size: 11px;
    position: fixed;
    left: 35%;
    top: 97%;
}



</style>
