<template>
  <div id="app">
    <!--    独立测试区1-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--      <check_admin_log msg="./components/check_admin_log.vue"></check_admin_log>-->

    <router-view></router-view>

<!--        <index msg="./components/index.vue"></index>-->
<!--      <check_page msg="./components/writer_page"></check_page>-->

<!--            <writer_menu msg="./components/writer_menu.vue"></writer_menu>-->
<!--   writer_page  报错-->
<!--        <writer_page  msg="./components/writer_page.vue"></writer_page>-->

<!--        <writer_menu_draft msg="./components/writer_menu_draft.vue"></writer_menu_draft>-->
<!--        <writer_menu_note msg="./components/writer_menu_note.vue"></writer_menu_note>-->
<!--        <writer_admin_log msg="./components/writer_admin_log"></writer_admin_log>-->

  </div>
</template>

<script>
  // import Vue from 'vue'
  // import HelloWorld from './components/HelloWorld.vue'

  // import check_admin_log from "./components/check_admin_log";  //查看方式下 用户登录页面
  // import index from './components/index.vue' //首页
  // import check_page from "./components/check_page";   //查看页面下  查看留言

  // import writer_menu from "./components/writer_menu";   //撰写页面菜单

  // writer_page  报错
  // import writer_page from "./components/writer_page";  //撰写页面下 编写页面

  // import writer_menu_draft from "./components/writer_menu_draft";   //撰写页面下的 查看草稿
  // import writer_menu_note from "./components/writer_menu_note";   //撰写页面下的 查看留言记录
  //  import writer_admin_log from "./components/writer_admin_log";  //撰写页面 发送指定用户

  export default {
    name: 'App',
    data(){
      return{

      }
    },
    components: {
      // HelloWorld,
      // check_admin_log,     //用户登录页面
      // index,                   //首页
      // check_page,           //查看页面下  查看留言
      // writer_menu,      //撰写页面菜单
      // writer_page,    //撰写页面下 编写页面
      // writer_menu_draft,   //撰写页面下的 查看草稿
      // writer_menu_note, //撰写页面下的 查看留言记录
      // writer_admin_log  //撰写页面 发送指定用户
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

</style>
