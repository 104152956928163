<!-- // 3 查看留言页面 -->

<template>
  

  <div id="writer_menu">
      <el-image :src="require('../../image/check_page.png')" class="poster"/>

      <div class="table_bg">
        <template>
          <!--            Dear框-->

          <div class="writer_page">
            <p style="top: 1%;left: 3%;position: absolute; font-weight: bold; font-size: 20px;">Dear</p>

            <div class="writer_Dear">
              <el-input v-model="dear_fname" readonly="true"></el-input >
            </div>
            <!--            <el-image :src="require('../../image/page.jpg')" class="page_background"/>-->
           
            <div class="page_background">
                <div class="page">
                  <el-input type="textarea" v-model="msg" :rows="8"  class="textarea" readonly></el-input >
                </div>
            
            <div class="font-bg">

            <!--            from框-->
            <div class="writer_from" style="top: 38%; margin-left:50%; width: 40%;">
                  <p style="margin-top: 4%; right:48%;  position: absolute; font-weight: bold; font-size: 20px;">From</p>
                  <el-input v-model="from_fname" label-width="30px" readonly="true">
                  </el-input >
            </div>

              <!--        返回键-->
              <router-link to="/index_home" class="img_writer1">
                <img src="../../image/back_icon.png" style="width: 65px; height: 30px;">
              </router-link>

            </div>

            </div>
          </div>
        </template>


      </div>



    </div>

  
  <!-- <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a> -->


</template>



<script>

  export default {

    data() {
      return {
        dear_fname: '', //接收人
        msg:'',
        from_fname: '' //发送人
      }
    },
    created() {
      this.getMsgData();
    },
    mounted() {
      this.getMsgData();
    },
    methods: {
      index() {
        this.$router.push('/index');//要跳转的界面
      },
      getMsgData() {
        let that = this
        this.$axios({
          method: 'get',
          url: "http://api.restmode.cn:5000/api/getNewMsg",
          params: {
            userId: sessionStorage.getItem("UserId"),
          }
        }).then(result => {
          if (result.data.code == 1) {
            let tableData = result.data.data[0];
            console.log(tableData)
            that.dear_fname = tableData.toname;
            that.from_fname = tableData.fromname;
            that.msg = tableData.msg;
          }
        }).catch((e) => {
          console.log(e)
        });
      }
    }

  }

</script>




<style scoped>
  .poster {
    position: absolute;
    /*margin-top:-180px;*/
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .table_bg{
    position: absolute;
    width: 89%;
    height: 50%;
    min-height: 20%;
    top: 18%;
    left: 5.5%;
    background: #ffffff;
    /*background: #d1902c;*/
  }

  .page{
    position: relative;
    width: 100%;
    /* height: 85%; */
    top: 3%;

  }
  .page_background{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 3%;
    top: 16%;
  }
  .textarea >>> .el-textarea__inner{
    font-size:23px !important;
    background: transparent;
    border: none;
    background-size: 100% 100%;
    background-image: url('../../image/page.png');
    border-color: #FFFFFF;
    /* resize: none; */
  }

  /*昵称输入框样式*/
  .writer_Dear >>> input{
    margin-top: 7%;
    margin-left: -14%;
    width: 47%;
    height: 28px;
    font-weight: bold;
    font-size: 16px;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    /*box-shadow: none;*/
    border-bottom: 1px solid #000000;

  }

  .writer_from >>> input{
    width:80%;
    background-color: #ffffff;
    height: 20%;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #000000;
    margin-top:14%;
  }
  .writer_page{
    position: relative;
    width: 92.5%;
    height: 100%;
    left: 3.8%;
  }

  .font-bg{
    position: absolute;
    width:110%;
    height:35%;
    right: -10%;
    top:95%;
    /*background: #ffffff;*/
    /*background: #d1902c;*/

  }

  /* #nav {
    padding: 20px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  } */


   /* 回车键 */
  .img_writer1{
    /*margin-bottom: 80%;*/
    position:absolute;
    left: 2%;
    /*margin-top: 2%;*/
    top: 60%;
  }


  .beian{
      font-family: "黑体";
      color: #ffffff;
      font-size: 11px;
      position: fixed;
      left: 35%;
      top: 100%;
  }



</style>

