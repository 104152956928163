<!--5 撰写页面的 查看发送过的记录 页面 -->


<template>
<div id="writer_menu_note">
    <div id="nav">
        <el-image :src="require('../../image/writer_menu_bg.png')" class="poster" />
        <div class="table_bg">
            <mt-button size="large" @click="writer_page" replace><img src="../../image/writer_talk.png" class="img_writer1"></mt-button>
            <mt-button size="large" @click="writer_menu_note" replace><img src="../../image/talk_record.png" class="img_writer2"></mt-button>
            <mt-button size="large" @click="writer_menu_draft" replace><img src="../../image/talk_draft.png" class="img_writer3"></mt-button>

            <el-table :data="tableData" stripe class="table"  height=86% border 
            :header-cell-style="{background:'#fcd7ba'}" >
                <el-table-column prop="sendtime" label="时间" width="60">
                </el-table-column>
                <el-table-column prop="msg" label="密语" width="250">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="50">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
                            移除
                        </el-button>

                    </template>
                </el-table-column>

            </el-table>

        </div>

    </div>

    <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a>

</div>
</template>

<script>
export default {
    name: 'writer_menu_note',
    data() {
        return {
            tableData: []
        };
    },
    created() {
        this.getMsgData();
    },
    mounted() {
        this.getMsgData();
    },
    methods: {
        deleteRow(index, rows) {
            console.log(index, rows[index].pkey)
            this.$axios({
                method: 'get',
                url: " http://api.restmode.cn:5000/api/delmsg",
                params: {
                    userId: sessionStorage.getItem("UserId"),
                    msgId: rows[index].pkey
                }
            }).then(result => {
                console.log(result.data);
                rows.splice(index, 1);
            }).catch((e) => {
                console.log(e)
            });
        },
        writer_page() {
            this.$router.push('/writer_page'); //要跳转的界面
        },
        writer_menu_note() {
            this.getMsgData()
            // this.$router.push('/writer_menu_note'); //要跳转的界面
            
        },
        writer_menu_draft() {
            this.$router.push('/writer_menu_draft'); //要跳转的界面
        },
        getMsgData() {
            let that = this
            this.$axios({
                method: 'get',
                url: "http://api.restmode.cn:5000/api/getmsg",
                params: {
                    userId: sessionStorage.getItem("UserId"),
                }
            }).then(result => {
                console.log(result.data);
                if (result.data.code == 1) {
                    that.tableData = result.data.data;
                    that.tableData = that.tableData.map(item => {
                        function formatDate(date) {
                            date = new Date(date);
                            var YY = date.getFullYear() + '-';
                            var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                            var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                            // var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                            // var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                            // var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                            return YY + MM + DD;
                        }
                        return {pkey:item.pkey, sendtime:formatDate(item.sendtime*1000), msg:item.msg, from:item.fromId, to:item.toId};
                    })
                }
            }).catch((e) => {
                console.log(e)
            });
        }
    },
}
</script>

<style scoped>
.poster {
    position: absolute;
    /*margin-top:-180px;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.table_bg {
    position: absolute;
    width: 93%;
    height: 70%;
    top: 20%;
    left: 4%;
    /*background: #0b0b0b;*/
    /* background: #e29e3b; */
    background-color: transparent !important;

}

.table {
    position: relative;
    /* background-color: transparent !important; */

    /*size: 200px 300px;*/
    /*width: 100%; margin-top: 180px; height: 400px;*/
    top: 4%;
    left: 1.9%;
    width: 95.3%;
    height: 80%;
    color:  #000;

;}

#nav {
    padding: 20px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
.img_writer1{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    right: 8%;
    width:20%;
    height: 8%;
   }

  .img_writer2{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    margin:0px auto;
    width:20%;
    height: 8%;
  }
  .img_writer3{
    /*margin-bottom: 80%;*/
    position:relative;
    top: 20px;
    left: 8%;
    width:20%;
    height: 8%;
  }


.beian{
    font-family: "黑体";
    color: #FFFFFF;
    font-size: 11px;
    position: fixed;
    left: 35%;
    top: 97%;
}


</style>
