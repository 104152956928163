<!-- // 1 登录页面 -->

<template>
<div id="check-admin-login">
    <el-image :src="require('../../image/admin_log.png')" class="admin_log_bg" />

    <div class="table_bg">

        <el-form class="login-container" :model="loginForm" label-position="left" label-width="0px" ref="loginForm">

            <!-- 账号 -->
            <el-form-item class="element.style" prop="userID" :rules="[{ required: true, message: '手机号错误'}]">
                <!-- <el-input type="text" style="width:90%; left: 5%;" 
                v-model="loginForm.userID" auto-complete="off" :placeholder="getPlaceholder" class="el-input_inner" >
                </el-input> -->

                <el-input type="text" style="width:90%; left: 5%;" 
                v-model="loginForm.userID" auto-complete="off" :placeholder="getPlaceholder" class="el-input_inner" >
                </el-input>
            </el-form-item>

            <!-- 确认按钮 -->

            <el-form-item >
                <el-button type="primary"  @click="checkForm('loginForm')"></el-button>

            </el-form-item>

        </el-form>

        <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2023133157号-1</a>

    </div>

</div>
</template>

<script>
export default {
    name: "check-admin-login",
    data() {
        return {
            loginForm: {
                userID: '',
            },
            responseResult: [],
        }
    },
    computed: {
        getPlaceholder() {
            return this.$store.getters.getPlaceholder;
        },
    },
    methods: {
    
        checkForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.checkPhone(this.loginForm.userID)){
                        this.submitForm(this.loginForm.userID);
                    }else{
                        this.$message({
                            message: '手机号填写错误！',
                            type: 'error'
                        }); 
                    }
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        submitForm(userId){
            let userIdType = this.$store.getters.getIdType;
            if (userIdType == ''){  //输入id页面
                userIdType = 'UserId';
                this.$store.commit('setUserId', userId);
                sessionStorage.setItem(userIdType,userId);
                this.$router.push("/index_home");
            }else if(userIdType == 'ToId'){  // 发送步骤四页面
                this.$store.commit('setToId', userId);
                sessionStorage.setItem(userIdType,userId);
                let fId = this.$store.getters.getUserId;
                let tId = userId;
                let fname = this.$store.getters.getFromName;
                let tname = this.$store.getters.getToName;
                let msg = this.$store.getters.getMsg;
                this.handleSetMsg(fId, tId, fname, tname, msg);
            }
            
        },
        handleSetMsg(fId, tId, fname, tname, msg){
            let that = this
            this.$axios({
            method: 'post',
            url: "http://api.restmode.cn:5000/api/setmsg",
            data:{
                userId:fId,
                toId:tId,
                fname:fname,
                tname:tname,
                msg:msg
            }
            }).then(result =>{
                if (result.data.code == 0){
                    this.$message({
                        message: result.data.message,
                        type: 'success'
                    });
                    that.$store.commit('setIdType', 'ToId');
                    that.$store.commit('setToId', '');
                    that.$router.go(-2);
                }else{
                    this.$message({
                        message: result.data.message,
                        type: 'error'
                    });
                }
            }).catch((e) => {console.log(e)});
        },
        checkPhone(phone){ 
            if(!(/\d{11}/.test(phone))){
                return false; 
            }
            return true;
        }
    }
}
</script>

<style scoped>

/* 页面样式 */
.page{
        position: relative;
    }

/*背景图样式*/
.admin_log_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*容器*/
.table_bg {
    position: absolute;
    width: 92.5%;
    height: 12%;
    top: 25%;
    left: 3.8%;
}

/*组件样式*/
.login-container {
    /* position: relative; */
    background-clip: padding-box;
    width: 92.5%;
    color: #FFFFFF;
}

/*手机号输入框样式*/
.el-input_inner>>>input {
    position: relative;
    background:url("../../image/heart.png") no-repeat 3%  center ;
    background-size: 8% 75%;
    /* background-color: #dec4a9; */
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #FFFFFF;
    text-indent: 10%;
    font-size: 18px;
    height: 30px;
    border-bottom: 2px solid #FFFFFF;
}

/*确定键样式*/
.el-button--primary {
    position: absolute;
    color: #FFFFFF;
    /* background: url("../../image/admin_log_confirm.jpeg") no-repeat fixed; */
    background-size: 100% 100%;
    border: none;
    background-color: transparent;
    opacity:0;
    transform: scale(4, 4);
    margin-top: 6%;
}



/*修改提示文字的颜色*/
.el-input_inner>>>input::-webkit-input-placeholder { /* WebKit browsers */ 
color: #FFFFFF; 
} 



.beian{
    font-family: "黑体";
    color: #FFFFFF;
    font-size: 11px;
    position: fixed;
    left: 35%;
    top: 97%;
}

</style>
